import React from 'react';
import { graphql, Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import SEO from '../components/seo';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../utils/constants';
import H1 from '../components/header1';

const useStyles = makeStyles({
  container: {
    marginLeft: 0,
    marginRight: 0,
    '@media (max-width:600px)': {
      marginTop: 20,
      marginBottom: 0,
    },
  },
  textGrid: {
    padding: '0px 0px 40px 160px',
    '@media (max-width:1024px)': {
      padding: '0px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  heroImage: {
    height: 400,
    maxWidth: 750,
    '@media (max-width:600px)': {
      marginLeft: -20,
      marginRight: -20,
      height: 'auto',
    },
  },
  title: {
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 750,
    '@media (max-width:600px)': {
      fontSize: 20,
      width: '100%',
      marginBottom: 26,
    },
  },
  body: {
    width: '60%',
    paddingTop: 60,
    '@media (max-width:900px)': {
      width: '80%',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  back: {
    fontSize: '0.7em',
    color: COLOR_CORAL,
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'none',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      textDecoration: 'none',
      color: COLOR_CORAL,
    },
    '@media (max-width:600px)': {
      fontSize: '8px !important',
    },
  },
  newsPost: {
    maxWidth: 750,
    whiteSpace: 'pre-line',
    '& p': {
      margin: 0,
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: COLOR_CORAL,
      },
      '&:visited': {
        color: COLOR_RED_VISITED,
      },
    },
  },
});

const BlogPostTemplate = ({ data }) => {
  const classes = useStyles();
  const post = data.contentfulBlogPost;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <SEO description={post.title} ogimage={post.heroImage.fluid.src} />
      <Container maxWidth="xl">
        <H1 title="NEWS" />
      </Container>
      <Helmet title={`${post.title} | ${siteTitle}`} />
      <Container className={classes.textGrid}>
        <Link to="/news" style={{ textDecoration: 'none' }}>
          <p style={{ textDecoration: 'none' }} className={classes.back}>
            BACK TO NEWS
          </p>
        </Link>
        <Img
          className={classes.heroImage}
          alt={post.title}
          fluid={post.heroImage.fluid}
        />
        <h2 className={classes.title}>{post.title}</h2>
        <p
          style={{
            display: 'block',
            fontWeight: 'bold',
          }}
        >
          {post.publishDate}
        </p>
        <span className={classes.newsPostObj}>
          <div
            className={classes.newsPost}
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </span>
        <Link to="/news" style={{ textDecoration: 'none' }}>
          <p style={{ textDecoration: 'none' }} className={classes.back}>
            BACK TO NEWS
          </p>
        </Link>
      </Container>
    </Container>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
